<script lang="ts">
  import Tailwindcss from "../Components/Tailwindcss.svelte";
  import { link } from "svelte-routing";
  import marked from "marked";
  import markdown from "./Index.md";
  import BrandHorizontal from "../Components/BrandHorizontal.svelte";

  const getProps = ({ location, href, isPartiallyCurrent, isCurrent }) => {
    const isActive = href === "/" ? isCurrent : isPartiallyCurrent || isCurrent;

    // The object returned here is spread on the anchor element's attributes
    if (isActive) {
      return { class: "active" };
    }
    return {};
  };
</script>

<Tailwindcss />

<div class=" container md:mx-auto">
  <div class="flex px-5">
    <div class="mr-6">
      <div class="sticky top-5">
        <h1 class="text-xl text-primary font-bold mt-5 mb-5">
          API Documentation
        </h1>

        <h2 class="text-primary font-bold">Authentication</h2>
        <ul class="p-0 m-0   text-xs">
          <li><a href="#authentication">Token authentication</a></li>
        </ul>

        <h2 class="text-primary font-bold mt-4">Stream Reference</h2>
        <ul class="p-0 m-0   text-xs">
          <li><a href="#register-stream">Register stream</a></li>
          <li><a href="#list-registered-streams">List streams</a></li>
          <li><a href="#delete-stream">Delete stream</a></li>
          <li><a href="#get-detailed-status-of-stream">Get stream</a></li>
        </ul>

        <h2 class="text-primary font-bold mt-4">Webhooks</h2>
        <ul class="p-0 m-0 text-xs">
          <li>
            <a href="#verification">Verification</a>
          </li>
          <li>
            <a href="#notification">Notification</a>
          </li>
        </ul>

        <h2 class="text-primary font-bold mt-4">Types</h2>
        <ul class="p-0 m-0  text-xs">
          <li>
            <a href="#typestreamregistration">Type::StreamRegistration</a>
          </li>
          <li><a href="#typeschedule">Type::Schedule</a></li>
          <li><a href="#typetransport">Type::Transport</a></li>
          <li><a href="#typetransportmethod">Type::TransportMethod</a></li>
          <li>
            <a href="#typetransportnotificationtype"
              >Type::TransportNotificationType</a
            >
          </li>
          <li><a href="#typeevent">Type::Event</a></li>
          <li><a href="#typeverification">Type::Verification</a></li>
          <li><a href="#typenotification">Type::Notification</a></li>
          <li>
            <a href="#typematchresultnotification"
              >Type::MatchResultNotification</a
            >
          </li>
          <li>
            <a href="#typemonitoringschedulenotification"
              >Type::MonitoringScheduleNotification
            </a>
          </li>
          <li>
            <a href="#typemonitoring">Type::Monitoring </a>
          </li>
          <li>
            <a href="#typestreamstatusresponse">Type::StreamStatusResponse </a>
          </li>
          <li>
            <a href="#typestreamstatus">Type::StreamStatus </a>
          </li>
          <li>
            <a href="#typetransportresponse">Type::TransportResponse </a>
          </li>
          <li>
            <a href="#typetransportstatus">Type::TransportStatus </a>
          </li>
          <li>
            <a href="#typestreamdetailedstatusresponse"
              >Type::StreamDetailedStatusResponse
            </a>
          </li>
          <li>
            <a href="#typegame">Type::Game </a>
          </li>
          <li>
            <a href="#typegamestatus">Type::Score </a>
          </li>
          <li>
            <a href="#typegamestatus">Type::Team </a>
          </li>
          <li>
            <a href="#typegamestatus">Type::GameStatus </a>
          </li>

          <li>
            <a href="#typemonitoringstatus">Type::MonitoringStatus </a>
          </li>
        </ul>

        <div class=" mt-10">
          <a class="text-secondary" href="/" use:link>Back to Demo</a>
        </div>
      </div>
    </div>
    <div class="flex-1 pt-5 ">
      <BrandHorizontal />
      <div class="markdown-body pt-20 ">
        {@html marked(markdown.html)}
      </div>
    </div>
  </div>
</div>
