<script lang="ts">
  import { fade } from "svelte/transition";
  import { MatchNotificationInterface } from "../Helpers/types";
  import { simple as format } from "timeago-simple";
  export let notification: MatchNotificationInterface;
</script>

<div class="border-b border-solid border-gray-200 py-4 px-0.5" transition:fade>
  <div class="flex">
    <div class="w-1/3 text-gray-300 font-semibold">
      {notification.gameTime}
    </div>
    <div
      class="w-1/3 text-center font-bold text-lg flex items-center justify-center"
    >
      <span class="flex-1 text-right">
        {#if notification.changes === "home" || notification.changes === "both"}
          <img src="assets/goal.svg" alt="Goal from Hometeam" class="mr-2" />
        {/if}
      </span>
      <span>
        {notification.home.score} : {notification.away.score}
      </span>
      <span class="flex-1">
        {#if notification.changes === "away" || notification.changes === "both"}
          <img src="assets/goal.svg" alt="Goal from Hometeam" class="ml-2" />
        {/if}</span
      >
    </div>
    <div class="w-1/3 text-right text-gray-300 font-semibold">
      {format(notification.date)}
    </div>
  </div>
</div>
