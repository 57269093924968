export const patchResult = (nextResult, state) => {
    if (!state || !nextResult) {
        return nextResult;
    }
    return mergeObjects(nextResult, state);
};
const mergeObjects = (dst, src) => {
    Object.keys(dst).forEach((key) => {
        if (dst[key] !== null && typeof dst[key] === "object") {
            mergeObjects(dst[key], src[key]);
        }
        else if (dst[key] === null) {
            dst[key] = src[key];
        }
    });
    return dst;
};
