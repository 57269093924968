<script context="module" lang="ts">
  declare type Unsubscriber = () => void;
</script>

<script lang="ts">
  import { link } from "svelte-routing";
  import {
    matchResult,
    matchResultActions,
    streamUrl,
    appMode,
  } from "./Helpers/stores";
  import Brand from "./Components/Brand.svelte";
  import { onDestroy } from "svelte";
  import Tailwindcss from "./Components/Tailwindcss.svelte";
  import MatchResult from "./Components/MatchResult.svelte";
  import UrlForm from "./Components/UrlForm.svelte";
  import { MatchResultInterface } from "./Helpers/types";

  let interval,
    stream: string,
    mode: "form" | "running",
    result: MatchResultInterface;
  let loadingState = false;
  let intervalTime = 25000;
  let subscriber: Array<Unsubscriber> = [];

  subscriber.push(
    matchResult.subscribe((_result) => {
      result = _result;
    })
  );

  subscriber.push(
    streamUrl.subscribe((_result) => {
      stream = _result;
    })
  );

  subscriber.push(
    appMode.subscribe((_mode) => {
      mode = _mode;
    })
  );

  onDestroy(() => {
    subscriber.forEach((unsubscribe) => {
      unsubscribe();
    });
  });

  let handleUrl = (): void => {
    matchResultActions.reset();
    appMode.set("running");
    getStremData();
    interval = setInterval(() => {
      getStremData();
    }, intervalTime);
  };

  let stopInterval = () => {
    appMode.set("form");
    clearInterval(interval);
  };

  async function getStremData() {
    const url = new URL("https://screeneye-api.ethlete.io/api/live");
    loadingState = true;
    url.searchParams.append("stream", stream);

    fetch(url.toString(), {
      method: "get",
    })
      .then((response) => response.json())
      .then((_result) => {
        loadingState = false;
        if (_result.error) {
          return;
        }
        matchResultActions.updater(_result);
      })
      .catch((_error) => {
        loadingState = false;
      });
  }
</script>

<Tailwindcss />

<div
  class="min-h-screen w-full md:w-screen flex justify-center items-center relative"
>
  <div class="w-full px-5 md:px-20 mb-20 flex justify-center ">
    <div class="w-full md:w-auto md:min-w-500 max-w-500 lg:mx-auto font-sans">
      <div class="flex justify-center mb-12">
        <Brand />
      </div>

      {#if mode === "form"}
        <UrlForm {handleUrl} />
      {/if}

      {#if mode === "running"}
        <MatchResult {stopInterval} />
      {/if}
    </div>
    <div class="absolute bottom-5 left-1/2 transform -translate-x-1/2">
      <a href="/api" use:link>API Documentation</a>
      <a href="https://www.ethlete.io" title="Go to Ethlete"
        ><img src="assets/brand_ethlete.svg" alt="Ethlete" class="w-36" /></a
      >
    </div>
  </div>
</div>
