import { writable } from "svelte/store";
import { patchResult } from "./patchResult";
export const defaultMatchReset = {
    gameTime: null,
    home: {
        score: null,
        name: null,
    },
    away: {
        score: null,
        name: null,
    },
    status: "unknown",
};
export const appMode = writable("form");
export const streamUrl = writable("twitch.tv/bvb_official");
export const matchNotifications = writable([]);
export const matchNotificationsActions = {
    reset: () => {
        matchNotifications.set([]);
    },
    add: (notification) => {
        if (!notification.gameTime) {
            return;
        }
        matchNotifications.update((notifications) => {
            if (!notifications[notifications.length - 1]) {
                notification.changes = "init";
            }
            return [notification].concat(notifications);
        });
    },
};
export const matchResult = writable(defaultMatchReset);
export const matchResultActions = {
    reset: () => {
        matchNotifications.set([]);
        matchResult.set(defaultMatchReset);
    },
    updater: (result) => {
        matchResult.update((state) => {
            patchResult(result, state);
            if (result.status === "unknown") {
                return result;
            }
            let notification = Object.assign({}, result);
            notification.date = new Date();
            checkNotificationMatchChanges(state, notification);
            return result;
        });
    },
};
const checkNotificationMatchChanges = (result, notification) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (((_a = notification === null || notification === void 0 ? void 0 : notification.home) === null || _a === void 0 ? void 0 : _a.score) > ((_b = result === null || result === void 0 ? void 0 : result.home) === null || _b === void 0 ? void 0 : _b.score) &&
        ((_c = notification === null || notification === void 0 ? void 0 : notification.away) === null || _c === void 0 ? void 0 : _c.score) > ((_d = result === null || result === void 0 ? void 0 : result.away) === null || _d === void 0 ? void 0 : _d.score)) {
        notification.changes = "both";
    }
    else if (((_e = notification === null || notification === void 0 ? void 0 : notification.away) === null || _e === void 0 ? void 0 : _e.score) > ((_f = result === null || result === void 0 ? void 0 : result.away) === null || _f === void 0 ? void 0 : _f.score)) {
        notification.changes = "away";
    }
    else if (((_g = notification === null || notification === void 0 ? void 0 : notification.home) === null || _g === void 0 ? void 0 : _g.score) > ((_h = result === null || result === void 0 ? void 0 : result.home) === null || _h === void 0 ? void 0 : _h.score)) {
        notification.changes = "home";
    }
    else {
        notification.changes = "init";
    }
    matchNotificationsActions.add(notification);
};
