<script lang="ts">
  export let stopInterval: () => void;

  import { onDestroy } from "svelte";
  import { slide } from "svelte/transition";
  import { matchResult, streamUrl } from "../Helpers/stores";
  import TwitchStream from "./TwitchStream.svelte";
  import ResultItem from "./ResultItem.svelte";
  import Notification from "./Notification.svelte";
  import { MatchResultInterface } from "../Helpers/types";

  let stream: string;
  let result: MatchResultInterface;

  const unsubscribe = matchResult.subscribe((_result) => {
    result = _result;
  });
  const unsubscribeStream = streamUrl.subscribe((_result) => {
    stream = _result;
  });

  onDestroy(unsubscribeStream);
  onDestroy(unsubscribe);
</script>

<div transition:slide>
  <div class="text-center">
    <button
      on:click={stopInterval}
      class="mb-6 focus:outline-none shadow-element border border-solid border-tertiary px-6 py-4 tracking-widest font-semibold leading-none rounded-full text-primary transition hover:bg-tertiary"
    >
      Change URL
    </button>
  </div>
  <div class="bg-black">
    <TwitchStream />
  </div>
  <div
    class="bg-gradient-to-b from-white to-blue-light rounded-b-lg shadow-element p-4"
  >
    <div class="flex justify-center">
      <div class="flex-1 flex items-center text-2xl font-bold">
        {#if result?.home?.name}
          {result?.home?.name}
        {/if}
      </div>
      <div class="flex justify-center">
        <ResultItem content={result?.home?.score} />
        <div
          class="flex items-center justify-center text-center py-2.5 rounded-xl mx-2 text-5xl font-bold"
        >
          :
        </div>

        <ResultItem content={result?.away?.score} />
      </div>
      <div class="flex-1 flex items-center justify-end text-2xl font-bold">
        {#if result?.away?.name}
          {result.away.name}
        {/if}
      </div>
    </div>
    <div class="text-center opacity-40 text-xs font-semibold">
      {#if result?.gameTime}
        {result.gameTime}
      {/if}
    </div>
  </div>
</div>
<Notification />
