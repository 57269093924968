<script context="module" lang="ts">
  declare const Twitch: any;
</script>

<script lang="ts">
  import { matchResult, streamUrl } from "../Helpers/stores";
  import { onDestroy } from "svelte";

  let stream;

  const unsubscribeStream = streamUrl.subscribe((_stream) => {
    stream = _stream;
  });

  let twitchInit = () => {
    let regExTwitch = new RegExp("twitch.tv/(.+)/?", "gm");
    let match = regExTwitch.exec(stream);
    if (match && match.length > 0) {
      let username = match[1];
      const options = {
        width: "100%",
        height: "100%",
        channel: username,
        muted: true,
      };
      new Twitch.Player("twitch-player", options);
    }
  };

  onDestroy(unsubscribeStream);
</script>

<svelte:head
  ><script
    src="https://player.twitch.tv/js/embed/v1.js"
    on:load={twitchInit}></script></svelte:head
>
<div id="twitch-player" class="aspect-w-16 aspect-h-9" />
