<script lang="ts">
  import { matchNotifications } from "../Helpers/stores";
  import NotificationItem from "./NotificationItem.svelte";
</script>

<div class="mt-12 max-h-250 overflow-y-auto px-1">
{#each $matchNotifications as notification}
  <div>
    <NotificationItem {notification} />
  </div>
{/each}
</div>