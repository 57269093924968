<script lang="ts">
  import { Router, Link, Route } from "svelte-routing";
  import Tailwindcss from "./Components/Tailwindcss.svelte";
  import Screeneye from "./Screeneye.svelte";
  import Docs from "./Docs/Index.svelte";
  export let url = "";
</script>

<Tailwindcss />

<Router {url}>
  <Route path="/" component={Screeneye} />
  <Route path="/api" component={Docs} />
</Router>
