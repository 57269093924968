<script lang="ts">
  import { streamUrl } from "../Helpers/stores";
  export let handleUrl: () => void;
</script>

<div class="flex justify-center">
  <div class="flex flex-col md:flex-row md:w-96">
    <input
      bind:value={$streamUrl}
      placeholder="Enter Twitch / Youtube URL"
      class="flex-1 rounded-full shadow-input px-6 py-4 leading-none placeholder-primary font-bold focus:outline-none"
    />

    <button
      on:click={handleUrl}
      class="md:ml-4 bg-secondary px-6 py-4 focus:outline-none leading-none rounded-full text-primary font-bold transition hover:text-white mt-3 md:mt-0"
    >
      Start
    </button>
  </div>
</div>
